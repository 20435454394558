define('ember-froala-editor/components/froala-editor', ['exports', 'ember', 'ember-froala-editor/templates/components/froala-editor', 'ember-string-ishtmlsafe-polyfill', 'ember-assign-polyfill', 'ember-get-config'], function (exports, _ember, _emberFroalaEditorTemplatesComponentsFroalaEditor, _emberStringIshtmlsafePolyfill, _emberAssignPolyfill, _emberGetConfig) {

  var FroalaEditorComponent = _ember['default'].Component.extend({
    layout: _emberFroalaEditorTemplatesComponentsFroalaEditor['default'],

    // Enable the ability to .extend() this Component
    // to define "default" options that will get merged
    // with any options passed in as an attribute
    mergedProperties: ['defaultOptions'],

    // Empty options hash, .extend() this Component
    // to define defaults. Ex: `defaultOptions:{ heightMin:200 }`
    defaultOptions: {},

    // Froala Editor prefixes all event names,
    // which is technically NOT a namespace,
    // and should be used when attaching event handlers
    // Note: Include the trailing dot
    eventPrefix: 'froalaEditor.',

    // Froala Editor event name that will trigger the
    // `update` action, mainly used to update the `content` value
    // and takes a cue from ember-one-way-controls
    updateEvent: 'contentChanged',

    // Option to return a SafeString when using on-*-getHtml event actions
    // By default, look at the current type of `content`
    returnSafeString: _ember['default'].computed('content', function () {
      return (0, _emberStringIshtmlsafePolyfill['default'])(this.get('content'));
    }),

    // Few depreciations to help with the 2.3.5 to 2.4.0 transition
    // These can be removed for 2.5.0
    update: function update() {
      _ember['default'].deprecate("froala-editor 'content' will no longer be updated by the component (two way bound), instead use the 'update' event action to '(mut)' the original property", this.get('_updateActionWarned'), {
        id: 'ember-froala-editor.updateAction',
        until: '2.5.0',
        url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
      });
      this.set('_updateActionWarned', true);
    },
    contentBindingEvent: _ember['default'].computed.deprecatingAlias('updateEvent', {
      id: 'ember-froala-editor.contentBindingEvent',
      until: '2.5.0',
      url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
    }),
    isSafeString: _ember['default'].computed.deprecatingAlias('returnSafeString', {
      id: 'ember-froala-editor.isSafeString',
      until: '2.5.5',
      url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
    }),
    _optionsChanged: _ember['default'].computed('options', {
      get: function get() {
        // Skip the first "get" from the `init()` hook
        if (!this.get('_optionsChangedFirst')) {
          this.set('_optionsChangedFirst', true);
        } else {
          _ember['default'].deprecate("froala-editor 'options' changed post-initialization no longer updates the editor, instead use the related froala-editor methods", this.get('_optionsChangedWarned'), {
            id: 'ember-froala-editor.optionsChanged',
            until: '2.5.0',
            url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
          });
          this.set('_optionsChangedWarned', true);
        }
      }
    }),

    // Private, internal Computed Property to handle SafeString support
    // and it will always return a string, even if `content` is null or undefined
    // Note: Both Strings and SafeStrings have a .toString() function
    _content: _ember['default'].computed('content', {
      get: function get() {
        var content = this.get('content');
        return content && typeof content.toString === 'function' ? content.toString() : '';
      }
    }),

    // Private, internal Computed Property to merge all the possible "options"
    _options: _ember['default'].computed('defaultOptions', 'options', {
      get: function get() {
        return (0, _emberAssignPolyfill['default'])({}, _ember['default'].getWithDefault(_emberGetConfig['default'], 'ember-froala-editor', {}), this.getWithDefault('defaultOptions', {}), this.getWithDefault('options', {}));
      }
    }),

    // Public facing API's for editor instance and state information
    // Note: The referenced properties are .set() on the init() hook
    editor: _ember['default'].computed.readOnly('_editor'),
    editorInitializing: _ember['default'].computed.readOnly('_editorInitializing'),
    editorInitialized: _ember['default'].computed.readOnly('_editorInitialized'),
    editorDestroying: _ember['default'].computed.readOnly('_editorDestroying'),
    editorDestroyed: _ember['default'].computed.not('editorInitialized'),

    /**********
     * Ember Lifecycle Hooks
     *
     * These will basically get everything going, from setup to tear down.
     * They are listed in the order they are typically called.
     **********/

    // Initialize the per-instance property defaults,
    // mainly for internal state flags and details.
    // https://guides.emberjs.com/v2.11.0/object-model/classes-and-instances/#toc_initializing-instances
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_editor', null);
      this.set('_editorInitializing', false);
      this.set('_editorInitialized', false);
      this.set('_editorDestroying', false);
      this.get('_optionsChanged'); // To monitor changes for depreciation notices
    }, // init()

    // Start the setup...
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.schedule('afterRender', this, this.initEditor);
    }, // didInsertElement()

    // Look for changes in `content`
    // and update directly on the editor
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var editor = this.get('_editor');
      var content = this.get('_content');

      if (editor && editor.html.get() !== content) {
        editor.html.set(content);
      }
    }, // didUpdateAttrs()

    // Start the tear down...
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.destroyEditor();
    }, // willDestroyElement()

    /**********
     * Froala Editor Lifecycle Methods
     *
     * The following are methods used to setup and tear down the Froala Editor.
     * The Ember Lifecycle hooks will typically call these methods,
     * although they _could_ be called in a custom event handler.
     **********/

    // Responsible for the actual initialization of the Froala Editor
    // and sets up required event handlers and property observers
    initEditor: function initEditor() {

      // Do NOT re-initialize, user should call reinitEditor()
      if (this.get('_editorInitialized') || this.get('_editorInitializing')) {
        return;
      }

      // Update the state flag so we know initialization has started
      this.set('_editorInitializing', true);

      // Init jQuery once...
      var $element = this.$();

      // Attach a one time 'froalaEditor.initialized' event handler
      // to know when initialization has finished, updating state flags
      // Note: Cannot be done via editor.events.on()
      //       since access to `editor` is not available yet
      $element.one(this.get('eventPrefix') + 'initialized', _ember['default'].run.bind(this, 'didInitEditor'));

      // Actual initialization of the Froala Editor
      $element.froalaEditor(this.get('_options'));
    }, // initEditor()

    // Just a quick method to destroy then initialize the editor,
    // the called methods will properly handle the current state
    reinitEditor: function reinitEditor() {
      this.destroyEditor();
      this.initEditor();
    }, // reinitEditor()

    // Responsible for destroying the Froala Editor and tearing down
    // everything that was setup in the initEditor() method
    destroyEditor: function destroyEditor() {

      // Do NOT try to destroy if there is nothing to destroy...
      // Note: !this.get('_editorInitialized') == this.get('editorDestroyed')
      if (!this.get('_editorInitialized') || this.get('_editorDestroying')) {
        return;
      }

      // Update the state flag so we know destroying has started
      this.set('_editorDestroying', true);

      // Note: Destroy event handler added in didInitEditor()

      // Actual destruction of the Froala Editor
      this.$().froalaEditor('destroy');
    }, // destroyEditor()

    /**********
     * Froala Editor Event Handlers
     *
     * Callbacks for event listeners setup by the
     * Froala Editor Lifecycle Methods above and
     * any event callbacks added by didInitEditor()
     **********/

    // Triggered by the 'froalaEditor.initialized' event, updates
    // component state flags, sets the original html/content, and
    // attaches event handlers directly to the editor
    didInitEditor: function didInitEditor(event, editor) {
      this.set('_editorInitializing', false);
      this.set('_editorInitialized', true);
      this.set('_editor', editor);

      // Set the initial HTML content
      editor.html.set(this.get('_content'));

      // Regex's used for replacing things in the property name
      var regexOnOrHtml = /(^on\-|\-getHtml$)/g;
      var regexHyphens = /\-/g;
      var regexDots = /\./g;

      // Go through all the property names looking for event handlers, on-*
      // Note: Use a for-in loop here over Object.keys() to get _all_ properties
      //       up the proto-chain, specifically needed when .extend()ing
      for (var propertyName in this) {

        // Just need on-* properties...
        // if ( !propertyName.startsWith('on-') ) // ES2015 (requires polyfill)
        if (propertyName.indexOf('on-') !== 0) {
          continue;
        }

        // Initialization actions will be triggered later,
        // that way the component is setup before calling the event action
        // if ( propertyName.startsWith('on-initialized') ) // ES2015 (requires polyfill)
        if (propertyName.indexOf('on-initialized') === 0) {
          continue;
        }

        // Convert the property name to what the event name would be
        var eventName = propertyName;
        eventName = eventName.replace(regexOnOrHtml, '');
        eventName = eventName.replace(regexHyphens, '.');

        // Special use case for the 'popups.hide.[id]' event
        // Ember usage would be 'on-popups-hide-id=(action)'
        // https://www.froala.com/wysiwyg-editor/docs/events#popups.hide.[id]
        // if ( eventName.startsWith('popups.hide.') ) // ES2016 (requires polyfill)
        if (eventName.indexOf('popups.hide.') === 0) {
          var id = eventName.replace('popups.hide.', '');
          id = id.replace(regexDots, '-'); // Convert back to hyphens
          eventName = 'popups.hide.[' + id + ']';
        }

        // Attach the appropriate event handler
        // if ( propertyName.endsWith('-getHtml') ) // ES2016 (requires polyfill)
        var getHtmlPos = propertyName.indexOf('-getHtml', -8);
        if (getHtmlPos !== -1 && getHtmlPos === propertyName.length - 8) {
          editor.events.on(eventName, _ember['default'].run.bind(this, this.didEditorEventReturnHtml, propertyName, editor), true);
        } else {
          editor.events.on(eventName, _ember['default'].run.bind(this, this.didEditorEvent, propertyName), true);
        }
      } // for ()

      // Get the update action and event name
      var update = this.get('update');
      var updateEvent = this.get('updateEvent');

      // If there is an update action and event,
      // then bind an event handler to get the html
      if (update && updateEvent) {
        editor.events.on(updateEvent, _ember['default'].run.bind(this, this.didEditorEventReturnHtml, 'update', editor), true);
      }

      // Add the destroy event handler
      // Run _after_ any other destroy handler
      editor.events.on('destroy', _ember['default'].run.bind(this, this.didDestroyEditor, editor), false);

      // Fire the "initialized" event actions (if defined)

      for (var _len = arguments.length, params = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        params[_key - 2] = arguments[_key];
      }

      if (this.get('on-initialized')) {
        this.didEditorEvent.apply(this, ['on-initialized'].concat(params));
      }
      if (this.get('on-initialized-getHtml')) {
        this.didEditorEventReturnHtml.apply(this, ['on-initialized-getHtml', editor].concat(params));
      }
    }, // didInitEditor()

    // Triggered by the 'froalaEditor.destroy' event,
    // updates component state flags
    didDestroyEditor: function didDestroyEditor() {
      this.set('_editor', null);
      this.set('_editorDestroying', false);
      this.set('_editorInitialized', false);
      // No need for '_editorDestroyed', the Computed
      // Property 'editorDestroyed' fills that need
    }, // didDestroyEditor()

    // Event handler for the 'on-*' property actions, which should be strings or functions
    // When the action is a function, return the handlers returned value back to the editor,
    // this allows support of "canceling" a Froala Editor event.
    // Note: This function is only triggered for Froala Editor events
    //       that have a related 'on-*' property defined for Ember actions
    didEditorEvent: function didEditorEvent(propertyName) {
      for (var _len2 = arguments.length, params = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        params[_key2 - 1] = arguments[_key2];
      }

      if (typeof this[propertyName] === 'function') {
        return this[propertyName].apply(this, [this].concat(params));
      } else {
        this.sendAction.apply(this, [propertyName, this].concat(params));
      }
    }, // didEditorEvent()

    // Event handler for the 'on-*-getHtml' property actions,
    // returns the editors current html as the first argument.
    // This is mainly useful when combined with the (mut) helper
    // Note: This function is only triggered for Froala Editor events
    //       that have a related 'on-*-getHtml' property defined for Ember actions
    didEditorEventReturnHtml: function didEditorEventReturnHtml(propertyName, editor) {

      // Get the HTML to return as the first argument
      var html = editor.html.get();

      // SafeString in = SafeString out..
      if (this.get('returnSafeString')) {
        html = _ember['default'].String.htmlSafe(html);
      }

      // When the action is a function, return the handlers returned value back to the editor,
      // this allows support of "canceling" a Froala Editor event.

      for (var _len3 = arguments.length, params = Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
        params[_key3 - 2] = arguments[_key3];
      }

      if (typeof this[propertyName] === 'function') {
        return this[propertyName].apply(this, [html, this].concat(params));
      } else {
        this.sendAction.apply(this, [propertyName, html, this].concat(params));
      }
    }, // didEditorEventReturnHtml()

    /**********
     * Froala Editor Method APIs
     *
     * Expose the Froala Editor methods in "Ember land",
     * basically proxies everything to the editor instance...
     **********/

    // Generic method() function that will proxy the call
    // to the Froala Editor public API for methods
    // Notes: Also handles editor state properly by returning a Promise if need be
    method: function method(methodName) {
      var _this = this,
          _arguments = arguments;

      // special, added, ember-froala-editor specific method
      if (methodName === 'reinit') {
        this.reinitEditor();

        // Editor should be initialized before calling the method
      } else if (this.get('_editorInitialized')) {
          var _$;

          return (_$ = this.$()).froalaEditor.apply(_$, arguments);
        } else {

          // Label for the following Promise, so it appears nicely in the Ember Inspector
          var promiseLabel = 'froala-editor: ' + ('Delayed method() call to \'' + methodName + '\' until editor is initialized');

          // Instead of throwing an error, lets return a Promise
          // that will call the method once the editor _is_ initialized
          return new _ember['default'].RSVP.Promise(function (resolve, reject) {

            // Create a one time event listener for the initialized event
            _this.$().one(_this.get('eventPrefix') + 'initialized', function () {

              // Try calling the Froala Editor method, returning the outcome
              try {
                var _$2;

                resolve((_$2 = _this.$()).froalaEditor.apply(_$2, _arguments));
              } catch (e) {
                reject(e);
              }
            } // () => {}
            ); // this.$().one()
          }, promiseLabel); // Ember.RSVP.Promise()
        } // else ( !editorInitialized )
    }, // method()

    // Also define the method() as a Component action, that way it could be invoked
    // as component.send('method', 'method.name', 'arg') from an event handler
    actions: {
      method: function method() {
        this.method.apply(this, arguments);
      } // method()
    } // :actions

  }); // Ember.Component.extend()

  FroalaEditorComponent.reopenClass({
    positionalParams: ['content', 'update', 'options']
  });

  exports['default'] = FroalaEditorComponent;
});