define('ember-froala-editor/helpers/merged-hash', ['exports', 'ember', 'ember-assign-polyfill'], function (exports, _ember, _emberAssignPolyfill) {
  exports.mergedHash = mergedHash;

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function mergedHash(params, hash) {
    // Assumes all params are objects
    if (params.length > 0) {
      return _emberAssignPolyfill['default'].apply(undefined, [{}].concat(_toConsumableArray(params), [hash]));
    } else {
      return hash;
    }
  }

  // mergedHash()

  exports['default'] = _ember['default'].Helper.helper(mergedHash);
});