define('ember-froala-editor/components/froala-content', ['exports', 'ember', 'ember-froala-editor/templates/components/froala-content'], function (exports, _ember, _emberFroalaEditorTemplatesComponentsFroalaContent) {

  var FroalaContentComponent = _ember['default'].Component.extend({
    layout: _emberFroalaEditorTemplatesComponentsFroalaContent['default'],
    classNames: ['fr-view']
  });

  FroalaContentComponent.reopenClass({
    positionalParams: ['content']
  });

  exports['default'] = FroalaContentComponent;
});